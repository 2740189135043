import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { ImageHeader } from "@ultralight-io/components"

import { Layout, EventsSection } from "../components"
import {compareDates} from "../utils/date-comparisons"

export default function EventsPage ({data}) {
  const page = data?.page?.data
  const image = getImage(page?.image?.localFile)

  const todayEvents = data.events.nodes.filter((event) => (
    compareDates(new Date(), new Date(event.data.date)) === 0
  ))
  const upcomingEvents = data.events.nodes.filter((event) => (
    compareDates(new Date(), new Date(event.data.date)) > 0
  )).sort((first, second) => (
    compareDates(new Date(second.data.date), new Date(first.data.date))
  ))
  const previousEvents = data.events.nodes.filter((event) => (
    compareDates(new Date(), new Date(event.data.date)) < 0 && !event.data.removeInPast
  )).sort((first, second) => (
    compareDates(new Date(first.data.date), new Date(second.data.date))
  ))

  return (
    <Layout eventsActive>
      {image &&
        <ImageHeader 
          className="object-cover object-center"
          title="Events"
          image={<GatsbyImage image={image} alt={page.image.altText} />}
        />
      }
      <div className="py-12 bg-white dark:bg-gray-800">
        <EventsSection title="Today" events={todayEvents} showTime />
        <EventsSection title="Upcoming" events={upcomingEvents} showTime />
        <EventsSection title="Previous" events={previousEvents} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    page: wpPage(slug: {eq: "events"}) {
      data: acfImageHeader {
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    events: allWpEvent(sort: {fields: acfEventData___date, order: ASC}) {
      nodes {
        title
        slug
        data: acfEventData {
          date
          description
          removeInPast
          youtubeId
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
